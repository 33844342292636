import { SecuredLayout } from '../Layout/SecuredLayout';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks';

export default function HealthIndex() {
    const { user } = useAuth();
    const [status, setStatus] = useState('');

    const fetchHealth = async () => {
        const resp = await fetch('/api/health', {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });
        const body = await resp.text();
        setStatus(`${resp.status} | ${body}`);
    };

    useEffect(() => {
        fetchHealth();
    }, []);

    return (
        <SecuredLayout>
            <div>{status}</div>
        </SecuredLayout>
    );
}
