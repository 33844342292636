const leftPad = (number: number) => {
    return number < 10 ? '0' + number : number.toString();
}

const formatDateToYyyyMMdd = (date: Date | null) => {
    if (!date) return "";
    const month = leftPad(date.getMonth() + 1)
    const dayOfMonth = leftPad(date.getDate())
    return `${date.getFullYear()}-${month}-${dayOfMonth}`
}

const formatDateToYyyyMMddhhmmss = (date: Date | null) => {
    if (!date) return "";
    const month = leftPad(date.getMonth() + 1)
    const dayOfMonth = leftPad(date.getDate())
    return `${date.getFullYear()}-${month}-${dayOfMonth} 00:00:00`
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export {formatDateToYyyyMMdd, formatDateToYyyyMMddhhmmss, formatDate}