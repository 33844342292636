import { CardActions, Modal, CardContent, Button } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ConfirmCard } from '../Layout/styles';
import { Quotation } from '../../@types/contractors';

interface PriceDetailsModalProps {
    open: boolean;
    onClose: () => void;
    quotation: Quotation | null;
}

export default function PriceDetailsModal({ open, onClose, quotation }: PriceDetailsModalProps) {
    if (!quotation) {
        return null;
    }

    let offerDescription = quotation.QuotationRequestContractor?.QuotationRequest?.OfferDescription;
    if (offerDescription === '') {
        offerDescription = quotation.QuotationRequestDetails?.OfferDescription;
    }
    let parsedOfferDescription = null;
    try {
        parsedOfferDescription = JSON.parse(offerDescription);
    } catch (error) {
        console.error(error);
    }
    const day = parsedOfferDescription?.days[0];

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                {parsedOfferDescription ? (
                    <CardContent sx={{ p: 2 }}>
                        <p>Utazóközönség: {parsedOfferDescription?.travelType}</p>
                        <p>Létszám: {parsedOfferDescription?.numberOfPassengers} fő</p>
                        <p>Szolgáltatási igény: {parsedOfferDescription?.serviceLevel}</p>
                        <h2>Az utazás részletei:</h2>
                        <p>
                            Indulás: {day?.departure} | {day?.source} | Érkezés: {day?.destination}
                        </p>
                        <p>
                            Visszaindulás: {day?.comeBackDeparture} | Érkezés: {day?.comeBackDestination}
                        </p>
                        <p>Az útvonal a következő:</p>
                        <p>
                            Menetrend: {day?.source} - {day?.destination}
                        </p>
                        <p>{day?.details}</p>
                    </CardContent>
                ) : (
                    <CardContent sx={{ p: 2 }}>
                        <h2>Nincs megjelenítendő adat</h2>
                    </CardContent>
                )}
                <CardActions sx={{ justifyContent: 'flex-end', py: 0, my: 0 }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Bezár
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
