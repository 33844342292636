export type Contractor = {
    ID: number;
    Uuid: string;
    Name: string;
    Site: string;
    Email: string;
    ServiceLevel: ServiceLevel;
    Buses: Bus[];
    PhoneNumbers: PhoneNumber[];
    Shires: Shire[];
    Quotation: Quotation[];
    Subscribed: boolean;
};

export type Bus = {
    ID: number;
    Manufacturer: string;
    Type: string;
    YearOfManufacture: string;
    Capacity: number;
};

export type PhoneNumber = {
    ID?: number;
    Value: string;
};

export type Quotation = {
    ID: string;
    OfferID: string;
    Price: number;
    Details: QuotationDetails;
    QuotationRequestContractor: QuotationRequestContractor;
    QuotationRequestDetails: QuotationRequestDetails;
    CreatedAt: string;
};

export type QuotationRequestDetails = {
    ID: number;
    QuotationID: string;
    OfferDescription: string;
    Ip: string;
    UserAgent: string;
    Comment: string | null;
};

export type QuotationDetails = {
    Ip: string;
    UserAgent: string;
    Comment: string | null;
};

export type QuotationRequestContractor = {
    ID: string;
    QuotationRequest: QuotationRequest;
    Accepted: boolean;
};

export type QuotationRequest = {
    OfferID: string;
    OfferDescription: string;
};

export type Shire = {
    Name: string;
};

export enum ServiceLevel {
    Basic = 'basic',
    Standard = 'standard',
    Premium = 'premium',
}

export type ContractorListResponse = {
    List: Contractor[];
    Count: number;
}
