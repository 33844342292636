import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import PriceDetailsModal from './PriceDetailsModal';
import { Quotation } from '../../@types/contractors';
import { formatDate } from '../../utils/date-format-handler';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';

interface PriceOffersProps {
    quotations: Quotation[];
}

export default function PriceOffers({ quotations }: PriceOffersProps) {
    const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>(null);

    return (
        <Paper sx={{ padding: 2 }}>
            <Typography variant={'h4'} mb={4}>
                Árajánlatok
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ügymenet szám</TableCell>
                            <TableCell align='right'>Összeg</TableCell>
                            <TableCell align='center'>Megjegyzés</TableCell>
                            <TableCell align='right'>Ajánlatadás időpontja</TableCell>
                            <TableCell align='center'>Részletek</TableCell>
                            <TableCell align='right'>Eredmény</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quotations.length ? (
                            quotations.map((quotation, index) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                    <TableCell component='th' scope='row'>
                                        {quotation.OfferID}
                                    </TableCell>
                                    <TableCell align='right'>{new Intl.NumberFormat('hu-HU').format(quotation.Price)} Ft</TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                        {quotation.Details?.Comment ? (
                                            <Tooltip title={quotation.Details.Comment}>
                                                <Typography>{quotation.Details.Comment}</Typography>
                                            </Tooltip>
                                        ) : null}
                                    </TableCell>
                                    <TableCell align='right'>{formatDate(quotation.CreatedAt)}</TableCell>
                                    <TableCell align='center'>
                                        <Button
                                            size='small'
                                            onClick={() => {
                                                setSelectedQuotation(quotation);
                                            }}>
                                            részletek
                                        </Button>
                                    </TableCell>
                                    <TableCell align='right'>
                                        {quotation.QuotationRequestContractor?.Accepted ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                        ) : (
                                            <CheckCircleIcon />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row' align={'center'} colSpan={5}>
                                    <Typography variant='h6' p={5}>
                                        Nincs megjeleníthető adat
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <PriceDetailsModal open={selectedQuotation !== null} onClose={() => setSelectedQuotation(null)} quotation={selectedQuotation} />
        </Paper>
    );
}
