import { Box } from '@mui/material';
import { Layout } from './Layout';

type Props = {
    children: JSX.Element | JSX.Element[];
};

const SecuredLayout: React.FC<Props> = ({ children }) => {
    return (
        <Layout>
            <Box>{children}</Box>
        </Layout>
    );
};

export { SecuredLayout };
