import Grid from '@mui/material/Unstable_Grid2';
import { Bus } from '../../@types/contractors';
import { useProvideSnackBar } from '../../hooks';
import CreateBusCard from './CreateBusCard';

import { BusCard } from './BusCard';

interface BusCardsProps {
    onAddBusCard: () => void;
    busList: Bus[];
    setBusList: (list: Bus[]) => void;
}

export default function BusCards({ onAddBusCard, busList, setBusList }: BusCardsProps) {
    const { showError } = useProvideSnackBar();

    const handleIsCardsData = () => {
        const allCardsHaveValues = busList.every(bus => {
            return bus.Manufacturer !== '' && bus.Type !== '' && bus.YearOfManufacture !== '' && bus.Capacity !== 0;
        });

        if (!allCardsHaveValues) {
            showError('Kérjük, töltse ki az összes busz adatot!');
        }
        return allCardsHaveValues;
    };

    const handleAddBusCardWithCheck = () => {
        if (handleIsCardsData()) {
            onAddBusCard();
        }
    };

    return (
        <Grid container spacing={1}>
            {busList.map((bus, index) => (
                <BusCard
                    bus={bus}
                    onDelete={id => setBusList(busList.filter(b => b.ID !== id))}
                    onUpdate={bus => {
                        const busListCopy = busList.map(b => {
                            if (b.ID !== bus.ID) {
                                return b;
                            }
                            return bus;
                        });
                        setBusList(busListCopy);
                    }}
                    key={index}
                />
            ))}
            <Grid xs={6} pt={0}>
                <CreateBusCard onAddBusCard={handleAddBusCardWithCheck} />
            </Grid>
        </Grid>
    );
}
