import {
    Breadcrumbs,
    Button,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Link,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Contractor, ContractorListResponse } from '../../@types/contractors';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { SecuredLayout } from '../Layout/SecuredLayout';
import Grid from '@mui/material/Unstable_Grid2';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowForwardIosTwoTone } from '@mui/icons-material';
import { Shires } from './Shires';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Switch from '@mui/material/Switch';

export default function ContractorListPage() {
    const { user } = useAuth();
    const { showError, showResponseError } = useProvideSnackBar();

    const [searchCount, setSearchCount] = useState<number>(0);
    const [contractorList, setContractorList] = useState<Contractor[]>([]);
    const [selectedShires, setSelectedShires] = useState<string[]>(Shires);
    const [subscribedFilter, setSubscribedFilter] = useState<string>('all');

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(true);

    const fetchGetContractorList = async () => {
        const filterParams = getFilterParams();
        try {
            const response = await fetch(`/api/v1/contractor?${filterParams}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.ok) {
                const data: ContractorListResponse = await response.json();
                setContractorList(data.List.sort((a, b) => a.Name.localeCompare(b.Name)));
                setSearchCount(data.Count);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az adat lekérdezése közben!`);
        }
    };

    const getFilterParams = () => {
        const params = new URLSearchParams();

        if (selectedShires.length > 0) {
            params.append('shires', selectedShires.join(','));
        }

        if (subscribedFilter === 'yes') {
            params.append('subscribed', 'true');
        }

        if (subscribedFilter === 'no') {
            params.append('subscribed', 'false');
        }
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        return params.toString();
    };

    useEffect(() => {
        fetchGetContractorList();
    }, []);

    const handleShireChecked = (shire: string) => {
        if (selectedShires.includes(shire)) {
            setSelectedShires(prevShires => prevShires.filter(s => s !== shire));
        } else {
            setSelectedShires(prevShires => [...prevShires, shire]);
        }
    };

    const handleSwitchChange = (checked: boolean) => {
        if (checked) {
            setSelectedShires([...Shires]);
        } else {
            setSelectedShires([]);
        }
        setChecked(checked);
    };

    useEffect(() => {
        if (selectedShires.length === Shires.length) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [selectedShires]);

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Alvállalkozók
                        </Link>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container p={1} alignItems={'flex-start'}>
                <Grid xs={12} lg={9}>
                    <Grid container sx={{ width: '100%', overflow: 'hidden' }} p={1}>
                        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)' }}>
                            <Table aria-label='customized table' stickyHeader={true}>
                                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                                    <TableRow>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14, paddingLeft: 16 }} align='left'>
                                            Név
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14 }} align='left'>
                                            Telefonszám
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14 }} align='left'>
                                            E-mail cím
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14 }} align='left'>
                                            Telephely
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14 }} align='left'>
                                            Szolgáltatási terület
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000', fontSize: 14 }} align='center'>
                                            Hírlevél
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '2px solid #000' }} align='left'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contractorList.length > 0 ? (
                                        contractorList.map((contractor, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ width: '150px', paddingLeft: 16 }} align='left'>
                                                    {contractor.Name}
                                                </TableCell>
                                                <TableCell style={{ width: '200px' }} align='left'>
                                                    {contractor.PhoneNumbers.map(pn => pn.Value).join(', ') || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ width: '180px' }} align='left'>
                                                    {contractor.Email}
                                                </TableCell>
                                                <TableCell style={{ width: '120px' }} align='left'>
                                                    {contractor.Site}
                                                </TableCell>
                                                <TableCell style={{ width: '200px' }} align='left'>
                                                    {contractor.Shires.map(shire => shire.Name).join(', ')}
                                                </TableCell>
                                                <TableCell style={{ width: '100px' }} align='center'>
                                                    {contractor.Subscribed ? (
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                    ) : (
                                                        <CancelIcon style={{ color: 'red' }} />
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ width: '120px' }} align='left'>
                                                    <Link sx={{ textDecoration: 'none' }} href={`/${contractor.Uuid}`}>
                                                        <Button
                                                            sx={{ px: 2, borderRadius: 25 }}
                                                            variant={'outlined'}
                                                            color='primary'
                                                            size={'small'}
                                                            endIcon={<ArrowForwardIosTwoTone />}>
                                                            Részletek
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align='center' colSpan={7}>
                                                <Typography variant='h6' p={5}>
                                                    Nincs megjeleníthető adat
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid xs={12} lg={3}>
                    <Grid container px={2}>
                        <Grid xs={12} mb={2}>
                            <Tooltip title='Keresés Név / E-mail cím / Telefonszám alapján'>
                                <TextField
                                    fullWidth
                                    variant='standard'
                                    label={'Keresés'}
                                    onChange={e => {
                                        const term = e.target.value;
                                        setSearchTerm(term);
                                    }}
                                    onKeyUp={e => {
                                        if (e.key === 'Enter') {
                                            fetchGetContractorList();
                                        }
                                    }}
                                    value={searchTerm}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid xs={12} mb={2} mt={2}>
                            <FormLabel id='service-area'>Keresés szolgáltatási terület alapján</FormLabel>
                            <FormGroup aria-label='service-area' row>
                                <Grid container mt={1}>
                                    {Shires.map((shire, index) => (
                                        <Grid xs={6} key={index}>
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        sx={{ p: 0.5 }}
                                                        checked={selectedShires.includes(shire)}
                                                        onChange={() => handleShireChecked(shire)}
                                                        name={shire}
                                                    />
                                                }
                                                label={<Typography variant='body2'>{shire}</Typography>}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid xs={12} mt={1}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <Typography>Összes megye kijelölés</Typography>
                                        <Switch
                                            checked={checked}
                                            onChange={e => handleSwitchChange(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>
                                </Grid>
                            </FormGroup>
                        </Grid>
                        <Grid xs={12} mb={2}>
                            <FormControl>
                                <FormLabel id='demo-radio-buttons-group-label'>Keresés feliratkozás alapján</FormLabel>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='all'
                                    name='radio-buttons-group'
                                    onChange={e => {
                                        const filterChange = e.target.value;
                                        setSubscribedFilter(filterChange);
                                    }}
                                    value={subscribedFilter}
                                    row>
                                    <FormControlLabel value='yes' control={<Radio />} label='Igen' />
                                    <FormControlLabel value='no' control={<Radio />} label='Nem' />
                                    <FormControlLabel value='all' control={<Radio />} label='Mutasd az összeset' />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} mb={2}>
                            <Button
                                sx={{ borderRadius: 25 }}
                                variant={'contained'}
                                color='primary'
                                endIcon={<SearchIcon />}
                                fullWidth
                                onClick={fetchGetContractorList}>
                                Keresés
                            </Button>
                        </Grid>
                        <Grid xs={12}>
                            <Typography paragraph>
                                Találatok:
                                {' ' + searchCount + ' '}
                                fő
                            </Typography>
                        </Grid>
                        <Grid>
                            <Tooltip title='Új vállalkozó rögzítése'>
                                <Fab
                                    size={'large'}
                                    component={RouterLink}
                                    to='/create'
                                    color='primary'
                                    sx={{
                                        borderRadius: 50,
                                        position: 'fixed',
                                        right: '50px',
                                        bottom: '30px',
                                        zIndex: 1000,
                                    }}>
                                    <PostAddIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SecuredLayout>
    );
}
