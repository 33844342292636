import { StyledBusCard } from '../Layout/styles';
import { CardContent, IconButton, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';
import { Bus } from '../../@types/contractors';
import { useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';

interface BusCardProp {
    bus: Bus;
    onDelete: (buszId: number) => void;
    onUpdate: (bus: Bus) => void;
}

const BusCard = ({ bus, onUpdate, onDelete }: BusCardProp) => {
    const { user } = useAuth();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();

    const [inEdit, setInEdit] = useState<boolean>(!bus.Manufacturer || !bus.Type || !bus.Capacity || !bus.YearOfManufacture);

    const [tempManufacturer, setTempManufacturer] = useState<string>(bus.Manufacturer);
    const [tempType, setTempType] = useState<string>(bus.Type);
    const [tempYearOfManufacture, setTempYearOfManufacture] = useState<string>(bus.YearOfManufacture);
    const [tempCapacity, setTempCapacity] = useState<number>(bus.Capacity);

    const handleSave = () => {
        const updatedBus = {
            ID: bus.ID,
            Manufacturer: tempManufacturer,
            Type: tempType,
            YearOfManufacture: tempYearOfManufacture,
            Capacity: tempCapacity,
        };

        if (JSON.stringify(updatedBus) === JSON.stringify(bus)) {
            setInEdit(false);
            return;
        }
        fetchUpdateBus(updatedBus);
        onUpdate(updatedBus);
    };

    const fetchUpdateBus = async (bus: Bus) => {
        try {
            const response = await fetch(`/api/v1/bus/${bus.ID}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(bus),
            });

            if (response.ok) {
                showSuccess('Sikeres frissítés!');
                setInEdit(false);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt a busz frissítése közben`);
        }
    };

    const handleDeleteBus = async () => {
        try {
            const response = await fetch(`/api/v1/bus/${bus.ID}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                showSuccess('Sikeres törlés!');
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt a busz törlése közben`);
        }
    };

    const handleResetData = () => {
        setTempManufacturer(bus.Manufacturer);
        setTempType(bus.Type);
        setTempCapacity(bus.Capacity);
        setTempYearOfManufacture(bus.YearOfManufacture);
    };

    return (
        <Grid xs={6}>
            <StyledBusCard variant={'elevation'}>
                <CardContent sx={{ m: 0, pb: '0!important', pt: 1, px: 0.5 }}>
                    <Grid container flexWrap={'wrap'} m={0} p={0}>
                        <Grid md={4}>
                            <TextField
                                label='Gyártó'
                                variant='standard'
                                value={tempManufacturer}
                                onChange={e => setTempManufacturer(e.target.value)}
                                size='small'
                                disabled={!inEdit}
                            />
                        </Grid>
                        <Grid md={4}>
                            <TextField
                                label='Típus'
                                variant='standard'
                                value={tempType}
                                onChange={e => setTempType(e.target.value)}
                                size='small'
                                disabled={!inEdit}
                            />
                        </Grid>
                        <Grid md={4} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                            <IconButton
                                title={'Busz szerkesztése / Mentése'}
                                size='small'
                                sx={{ padding: 0 }}
                                onClick={() => {
                                    if (!inEdit) {
                                        setInEdit(true);
                                        return;
                                    }
                                    handleSave();
                                }}>
                                {inEdit ? <SaveIcon color={'info'} /> : <EditIcon color={'info'} />}
                            </IconButton>
                            <IconButton
                                title={'Busz törlése / Változtatások visszavonása'}
                                size='small'
                                sx={{ padding: 0 }}
                                onClick={() => {
                                    setInEdit(false);
                                    if (inEdit) {
                                        handleResetData();
                                        return;
                                    }
                                    handleDeleteBus();
                                    onDelete(bus.ID);
                                }}>
                                {inEdit ? <UndoIcon color={'info'} /> : <ClearIcon color={'error'} />}
                            </IconButton>
                        </Grid>
                        <Grid md={4}>
                            <TextField
                                label='Gyártási év'
                                variant='standard'
                                value={tempYearOfManufacture}
                                onChange={e => setTempYearOfManufacture(e.target.value)}
                                size='small'
                                disabled={!inEdit}
                            />
                        </Grid>
                        <Grid md={8}>
                            <TextField
                                label='Szállítható személyek száma'
                                variant='standard'
                                value={tempCapacity}
                                onChange={e => {
                                    const capacity = parseInt(e.target.value);
                                    if (!isNaN(capacity)) {
                                        setTempCapacity(capacity);
                                    }
                                }}
                                inputProps={{ type: 'number', min: '0' }}
                                size='small'
                                disabled={!inEdit}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </StyledBusCard>
        </Grid>
    );
};

export { BusCard };
