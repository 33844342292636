import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, CardContent } from '@mui/material';
import { StyledBusCard } from '../Layout/styles';
import Grid from '@mui/material/Unstable_Grid2';

interface CreateBusCardProps {
    onAddBusCard: () => void;
}

export default function CreateBusCard({ onAddBusCard }: CreateBusCardProps) {
    return (
        <Grid>
            <StyledBusCard variant={'elevation'}>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                    <Button title={'Busz hozzáadása'} onClick={onAddBusCard}>
                        <AddCircleOutlineIcon color={'primary'} fontSize='large' />
                    </Button>
                </CardContent>
            </StyledBusCard>
        </Grid>
    );
}
