import { SecuredLayout } from '../Layout/SecuredLayout';
import Grid from '@mui/material/Unstable_Grid2';
import BusCards from './BusCards';
import {
    Box,
    Breadcrumbs,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import PriceOffers from './PriceOffers';
import { useEffect, useState } from 'react';
import { StyledDetailsCard } from '../Layout/styles';
import ServiceAreaMap from './ServiceAreaMap';
import { Bus, Contractor, PhoneNumber, Quotation, ServiceLevel, Shire } from '../../@types/contractors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

export default function DetailsPage() {
    const { user } = useAuth();
    const { uuid } = useParams<{ uuid: string }>();
    const navigate = useNavigate();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [site, setSite] = useState<string>('');
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([{ Value: '' }]);
    const [serviceLevel, setServiceLevel] = useState<ServiceLevel | ''>('please_select' as ServiceLevel);

    const [selectedShires, setSelectedShires] = useState<Shire[]>([]);

    const [quotation, setQuotation] = useState<Quotation[]>([]);

    const [busForm, setBusForm] = useState<Bus[]>([]);
    const [originalState, setOriginalState] = useState<Contractor>({
        ID: 0,
        Uuid: '',
        Name: '',
        Email: '',
        Site: '',
        PhoneNumbers: [],
        Shires: [],
        ServiceLevel: 'please_select' as ServiceLevel,
        Buses: [],
        Quotation: [],
        Subscribed: true,
    });

    const fetchCreateBus = async (contractorID: string) => {
        try {
            const response = await fetch(`/api/v1/contractor/${contractorID}/bus/create`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(busForm),
            });
            if (response.ok) {
                const data = await response.json();
                return data.ID;
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az adat elküldése közben`);
        }
    };

    const handleCreateBus = async (contractorID: string) => {
        if (contractorID === '') {
            showError('Kérjük, töltse ki a törzsadatokat majd kattintson a mentés gombra!');
            return;
        }
        const busID = await fetchCreateBus(contractorID);

        if (busID) {
            setBusForm(prev => [
                ...prev,
                {
                    ID: busID,
                    Manufacturer: '',
                    Type: '',
                    YearOfManufacture: '',
                    Capacity: 0,
                },
            ]);
        }
    };

    const fetchCreateContractor = async () => {
        try {
            const response = await fetch('/api/v1/contractor', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    Name: name,
                    Email: email,
                    Site: site,
                    PhoneNumbers: phoneNumbers,
                    Shires: selectedShires,
                    ServiceLevel: serviceLevel,
                }),
            });
            if (response.ok) {
                showSuccess('Sikeres mentés!');
                navigate('/');
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az adat elküldése közben`);
        }
    };

    const handleShiresClick = (shireName: string) => {
        if (selectedShires.some(shire => shire.Name === shireName)) {
            setSelectedShires(prevShires => prevShires.filter(shire => shire.Name !== shireName));
            return;
        }
        setSelectedShires(prevShires => [...prevShires, { Name: shireName }]);
    };

    const handleAddPhoneNumber = () => setPhoneNumbers(prevPhoneNumbers => [...prevPhoneNumbers, { Value: '' }]);

    const handleDeletePhoneNumber = (index: number) => {
        const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
        setPhoneNumbers(newPhoneNumbers);
    };

    const fetchGetContractor = async (uuid: string) => {
        try {
            const response = await fetch(`/api/v1/contractor/${uuid}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.ok) {
                const existingContractor: Contractor = await response.json();
                setName(existingContractor.Name);
                setEmail(existingContractor.Email);
                setSite(existingContractor.Site);
                setPhoneNumbers(existingContractor.PhoneNumbers.map(phoneNumber => ({ ID: phoneNumber.ID, Value: phoneNumber.Value })));
                setServiceLevel(existingContractor.ServiceLevel);
                setSelectedShires(existingContractor.Shires);
                setBusForm(existingContractor.Buses);
                setQuotation(existingContractor.Quotation);
                setOriginalState(existingContractor);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az adat lekérdezése közben`);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchGetContractor(uuid);
        }
    }, [uuid]);

    const fetchUpdateContractor = async (uuid: string) => {
        const contractorToUpdate = {
            Name: name,
            Email: email,
            Site: site,
            ServiceLevel: serviceLevel,
            PhoneNumbers: phoneNumbers,
            Shires: selectedShires,
            Buses: busForm,
        };
        try {
            const response = await fetch(`/api/v1/contractor/${uuid}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(contractorToUpdate),
            });

            if (response.ok) {
                showSuccess('Az adatok sikeresen frissítve lettek!');
                fetchGetContractor(uuid);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az adat frissítése közben`);
        }
    };

    const handleSaveContractor = () => {
        if (uuid) {
            fetchUpdateContractor(uuid);
            return;
        }
        fetchCreateContractor();
    };

    const hasChanges = () => {
        const data = {
            Name: name,
            Email: email,
            Site: site,
            ServiceLevel: serviceLevel,
            PhoneNumbers: phoneNumbers.map(phoneNumber => phoneNumber.Value),
            Shires: selectedShires,
            Buses: busForm,
        };
        return (
            JSON.stringify({
                    Name: originalState.Name,
                    Email: originalState.Email,
                    Site: originalState.Site,
                    ServiceLevel: originalState.ServiceLevel,
                    PhoneNumbers: originalState.PhoneNumbers.map(phoneNumber => phoneNumber.Value),
                    Shires: originalState.Shires,
                    Buses: originalState.Buses,
            }) !== JSON.stringify(data)
        );
    };

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Alvállalkozók
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                            {!uuid ? (
                                <>
                                    <PersonAddAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Új alvállalkozó rögzítése
                                </>
                            ) : (
                                <>
                                    <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    {name}
                                </>
                            )}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container sx={{ width: '100%', height: 'fit-content' }} p={2} flexDirection={'row'} alignItems={'flex-start'} spacing={3}>
                <Grid xs={4}>
                    <StyledDetailsCard>
                        <Typography variant={'h4'} ml={1} mt={2}>
                            Törzsadatok
                        </Typography>

                        <Grid container>
                            <Grid xs={6}>
                                <TextField label='Név' variant='standard' fullWidth value={name} onChange={e => setName(e.target.value)} />
                            </Grid>

                            <Grid xs={6}>
                                <TextField label='Telephely' variant='standard' fullWidth value={site} onChange={e => setSite(e.target.value)} />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid xs={6}>
                                <TextField label='E-mail cím' variant='standard' fullWidth value={email} onChange={e => setEmail(e.target.value)} />
                            </Grid>

                            <Grid xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id='service-label' variant='standard'>
                                        Szolgáltatási szint
                                    </InputLabel>
                                    <Select
                                        variant='standard'
                                        label='Szolgáltatási szint'
                                        value={serviceLevel}
                                        onChange={e => setServiceLevel(e.target.value as ServiceLevel | '')}>
                                        <MenuItem value={'please_select'}>Kérem válasszon...</MenuItem>
                                        <MenuItem value={ServiceLevel.Basic}>Basic</MenuItem>
                                        <MenuItem value={ServiceLevel.Standard}>Standard</MenuItem>
                                        <MenuItem value={ServiceLevel.Premium}>Premium</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent={'left'}>
                            {phoneNumbers.map((phoneNumber, index) => (
                                <Grid xs={6} key={index}>
                                    <TextField
                                        label='Telefonszám'
                                        variant='standard'
                                        fullWidth
                                        value={phoneNumber.Value}
                                        onChange={e => {
                                            setPhoneNumbers(prevPhoneNumbers => {
                                                const newPhoneNumbers = [...prevPhoneNumbers];
                                                newPhoneNumbers[index].Value = e.target.value;
                                                return newPhoneNumbers;
                                            });
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {phoneNumbers.length === 1 && index === 0 ? (
                                                        <IconButton title={'Telefonszám hozzáadása'} onClick={handleAddPhoneNumber}>
                                                            <AddCircleOutlineIcon color={'primary'} />
                                                        </IconButton>
                                                    ) : (
                                                        <>
                                                            {index === phoneNumbers.length - 1 && (
                                                                <IconButton title={'Telefonszám hozzáadása'} onClick={handleAddPhoneNumber}>
                                                                    <AddCircleOutlineIcon color={'primary'} />
                                                                </IconButton>
                                                            )}
                                                            <IconButton title={'Telefonszám törlése'} onClick={() => handleDeletePhoneNumber(index)}>
                                                                <RemoveCircleOutlineIcon color={'error'} />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container>
                            <Typography variant={'h5'} ml={1} mt={2}>
                                Szolgáltatási terület
                            </Typography>
                            <Box sx={{ width: '1200px', height: '400px' }} mb={4}>
                                <ServiceAreaMap onCountyClick={handleShiresClick} selectedShires={selectedShires.map(shire => shire.Name)} />
                            </Box>
                        </Grid>
                    </StyledDetailsCard>
                </Grid>

                <Grid xs={3}>
                    <StyledDetailsCard>
                        <Typography variant={'h4'} ml={2} mt={2}>
                            Buszok
                        </Typography>
                        <BusCards
                            onAddBusCard={() => {
                                handleCreateBus(!uuid ? '' : uuid);
                            }}
                            busList={busForm}
                            setBusList={setBusForm}
                        />
                    </StyledDetailsCard>
                </Grid>
                {uuid && (
                    <Grid xs={5}>
                        <PriceOffers quotations={quotation} />
                    </Grid>
                )}
            </Grid>
            <Grid>
                {hasChanges() && (
                    <Tooltip title='Mentés'>
                        <Fab
                            size={'large'}
                            onClick={handleSaveContractor}
                            color='primary'
                            sx={{
                                borderRadius: 50,
                                position: 'fixed',
                                right: '50px',
                                bottom: '30px',
                                zIndex: 1000,
                            }}>
                            <SaveIcon />
                        </Fab>
                    </Tooltip>
                )}
            </Grid>
            <Grid>
                <Tooltip title='Vissza a lista nézethez'>
                    <Fab
                        size={'large'}
                        component={RouterLink}
                        to='/'
                        color='info'
                        sx={{
                            borderRadius: 50,
                            position: 'fixed',
                            right: '120px',
                            bottom: '30px',
                            zIndex: 1000,
                        }}>
                        <ArrowBackIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </SecuredLayout>
    );
}
