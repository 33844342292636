import React from 'react';
import { Route, Routes } from 'react-router';
import { NotFoundPage } from './Common';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { ProvideAuth } from '../hooks';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import HealthIndex from './Health/Health';
import DetailsPage from './Contractors/DetailsPage';
import ListPage from './ContractorsList/ListPage';


const StyledApp = styled(Box)`
    background-image: url('/images/brb_logo_vertical_white.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#42C4AB',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledApp>
                <BrowserRouter>
                    <ProvideAuth>
                        <ProvideSnackBar>
                            <Routes>
                                <Route path='/create' element={<DetailsPage />} />
                                <Route path='/:uuid' element={<DetailsPage />} />
                                <Route path='/' element={<ListPage />} />
                                <Route path='/health' element={<HealthIndex />} />
                                <Route path='/*' element={<NotFoundPage />} />
                            </Routes>
                        </ProvideSnackBar>
                    </ProvideAuth>
                </BrowserRouter>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
