import React from 'react';
import {Box, IconButton, List, ListItem, ListItemText, Typography} from '@mui/material';
import { getErrorMessage } from '../utils/response-resolver';
import { Props } from '../@types';
import { closeSnackbar, SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const useProvideSnackBar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showError = (msg: JSX.Element | string) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    const showResponseError = async (response: Response) => {
        const body = await response.json();
        const errorMessage = (
            <Box>
                <Typography paragraph m={0} p={0}>{`${response.status} | ${getErrorMessage(response)} `}</Typography>
                <Typography paragraph component={'small'} m={0} p={0}>
                    ({`${body.Error || response.statusText}`})
                </Typography>
                {body.errors && (
                    <List>
                        {Object.keys(body.errors).map((errorKey: string) => (
                            <ListItem sx={{ p: 0, m: 0 }} key={errorKey}>
                                <ListItemText primary={body.errors[errorKey]} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        );
        showError(errorMessage);
    };

    const showSuccess = (msg: JSX.Element | string) => {
        enqueueSnackbar(msg, {
            variant: 'success',
        });
    };

    return {
        showError,
        showSuccess,
        showResponseError,
    };
};

const action = (snackbarId: SnackbarKey) => (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
        <CloseIcon sx={{ color: 'white' }} />
    </IconButton>
);

const ProvideSnackBar = ({ children }: Props) => {
    return (
        <SnackbarProvider
            maxSnack={10}
            autoHideDuration={6000}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            action={action}>
            {children}{' '}
        </SnackbarProvider>
    );
};

export { useProvideSnackBar, ProvideSnackBar };
